<template>
  <div class="body mobile flex flex-col">
    <section class="header notice">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/profile')"
        >
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">文字大小</span>
      <div class="flexH width right"></div>
    </section>
    <section class="main notice flex-1 flex flex-col justify-between">
      <div>
        您可以點擊下方按鈕，選擇您在閱讀上舒適的文字字級大小
        <div class="flex">
          <button
            class="button border-primary bg-white"
            :class="{ 'button border-0 text-white bg-primary': activeBtn === 's' }"
            @click="activeBtn = 's'"
          >
            小</button
          ><button
            class="button border-primary bg-white"
            :class="{ 'button border-0 text-white bg-primary': activeBtn === 'm' }"
            @click="activeBtn = 'm'"
          >
            中</button
          ><button
            class="button border-primary bg-white"
            :class="{ 'button border-0 text-white bg-primary': activeBtn === 'l' }"
            @click="activeBtn = 'l'"
          >
            大
          </button>
        </div>
        <div class="my-2">預覽參考文字：</div>
        <div class="my-2" :style="fontSize">
          「雲林幣扭一下」為雲林縣數位縣民平台，民眾可透過 App
          閱讀政令、參與活動賺取雲林幣，雲林幣可以在雲林縣內的合作商家進行購物商品的兌換或折扣，未來更會開放多元豐富的線上線下活動，供民眾一同參與。
        </div>
      </div>
      <div>
        <button
          class="button border-0 text-white"
          @click="confirm"
        >
          確定變更
        </button>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "E11",
  data() {
    let activeBtn = 'm'
    const fontScale = localStorage.getItem('fontScale')
    const m = {
        s: "12px",
        m: "16px",
        l: "20px",
      }
    if (fontScale) {
      const [scale] = Object.entries(m).find(([, v]) => {
        return fontScale === v
      })
      activeBtn = scale
    }
    return {
      activeBtn,
      m
    }
  },
  computed: {
    fontSize() {
      return `font-size: ${this.m[this.activeBtn]}`
    }
  },
  methods: {
    confirm() {
      const el = document.querySelector('html')
      const fs = this.m[this.activeBtn]
      el.style.fontSize = fs
      localStorage.setItem('fontScale', fs)
    }
  },
};
</script>
